<template>
  <div id="companiesContainer">

    <vs-breadcrumb class="mb-5">
      <li>
        <router-link to="/">Domov</router-link>
        <span class="vs-breadcrum--separator">/</span></li>
      <li aria-disabled="page" class="disabled-link">Donacije<span class="vs-breadcrum--separator">/</span></li>
      <li aria-current="page" class="active">Donacije zasebnikov</li>
    </vs-breadcrumb>

    <vs-button radius size="large" color="success" icon-pack="feather" icon="icon-plus-circle" id="addFabBtn"
               title="Vpiši novo donacijo" @click.stop="addDonation()"></vs-button>


    <vs-row>
      <vs-col vs-type="flex" vs-justify="start" vs-align="center" vs-w="12">
        <vs-button color="primary" size="small" class="mt-1 mr-2" icon="keyboard_arrow_left"
                   @click="() => {selectedDate = moment(selectedDate).subtract(1, 'M').toDate(); onDateChange()}"></vs-button>
        <datepicker :minimumView="'month'" :maximumView="'year'" :language="slSI" :format="customDateFormatter"
                    :monday-first="true"
                    @input="onDateChange" v-model="selectedDate"></datepicker>
        <vs-button color="primary" size="small " class="mt-1 ml-2" icon="keyboard_arrow_right"
                   @click="() => {selectedDate = moment(selectedDate).add(1, 'M').toDate(); onDateChange()}"></vs-button>

        <v-select id="unit" :options="unitsData" class="ml-20" @input="onUnitChange" style="min-width: 300px"
                  v-model="unitId" placeholder="Filtriraj zadetke po enoti"
                  :reduce="item => item.id"
                  v-if="$globalFunctions.isAdmin() && unitsData"
                  :clearable="false"
                  label="name">

          <template v-slot:option="option">
            <vs-list-item
              :title="option.name"
              :subtitle="option.coveredMunicipalities">
              <template slot="avatar" v-if="option.name">
                <vs-avatar size="large"
                           :text="option && option.name && option.name.toString().substr(0,1) + (option.name.toString().match(/\d/g) ? option.name.toString().match(/\d/g).join('') : '')"
                           class="mr-5"/>
              </template>
            </vs-list-item>
          </template>

          <template v-slot:selected-option="option">
            <vs-list-item
              :title="option.name"
              :subtitle="option.coveredMunicipalities">
              <template slot="avatar" v-if="option.name">
                <vs-avatar size="large"
                           :text="option && option.name && option.name.toString().substr(0,1) + (option.name.toString().match(/\d/g) ? option.name.toString().match(/\d/g).join('') : '')"
                           class="mr-5"/>
              </template>
            </vs-list-item>
          </template>

          <template v-slot:no-options="{ search, searching }">
            <template v-if="searching">
              Ni rezultatov za iskalni pojem <em>{{ search }}</em>.
            </template>
            <em style="opacity: 0.5;" v-else>Poskusi iskati z drugim pojmom.</em>
          </template>
        </v-select>

        <!--        <download-excel :data="donationsData"-->
        <!--                        :fields="fields_to_export"-->
        <!--                        worksheet="Donacije zasebnikov"-->
        <!--                        :name="moment(selectedDate).year() + '_' + (moment(selectedDate).month()+1) + '_donacije_zasebnikov.xls'"-->
        <!--                        class="ml-auto"-->
        <!--                        v-if="donationsData && donationsData.length > 0">-->
        <!--          <vs-button color="success" icon="description">Izvozi v Excel</vs-button>-->
        <!--        </download-excel>-->
        <vs-button class="ml-auto" color="success" icon="description" @click="exportData"
                   v-if="donationsData && donationsData.length > 0">Izvozi v Excel
        </vs-button>

        <vs-button class="ml-3" color="primary" icon="upload" @click="() => { importMode = !importMode;}"
                   v-if="$globalFunctions.isAdmin()">Uvozi iz bančnega prometa
        </vs-button>

      </vs-col>
    </vs-row>

    <vs-row v-if="importMode">
      <vs-col vs-type="flex" vs-justify="start" vs-w="12">
        <div class="vx-card mt-5 pb-5">
          <div class="vx-card__header">
            <div class="vx-card__title">
              <h4 class="">Uvoz bančnega prometa</h4>
              <div class="clearfix"></div>
            </div>
          </div>
          <div class="vx-card__collapsible-content">
            <div class="vx-card__body">

              <vs-row>
                <vs-col vs-type="flex" vs-justify="start" vs-align="start" vs-w="12">

                  <input type="file" ref="file" @change="fileChanged" accept="application/xml"
                         class="mt-1"/>
                </vs-col>

              </vs-row>
              <vs-row v-if="donationDataToImport && donationDataToImport.length > 0">
                <vs-col class="mt-5">

                  <vs-table :data="donationDataToImport">
                    <template slot="header">

                      <div class="d-flex mi-align-horizontal-left">
                        <div class="d-inline-block float-left">
                          <h2>Donacije zaznane iz bančnega prometa
                          </h2>
                        </div>
                        <vs-chip class="mt-1 ml-3">{{ donationDataToImport.length }}</vs-chip>
                      </div>

                    </template>
                    <template slot="thead">
                      <vs-th sort-key="id">
                        <!-- empty -->
                      </vs-th>
                      <vs-th sort-key="donationDate">
                        Datum
                      </vs-th>
                      <vs-th sort-key="donorName">
                        Donator
                      </vs-th>
                      <vs-th sort-key="donorAddress">
                        Naslov
                      </vs-th>
                      <vs-th sort-key="donationAmount">
                        Znesek
                      </vs-th>
                      <vs-th sort-key="unit_id">
                        Enota
                      </vs-th>
                      <vs-th sory-key="donationReference">
                        Referenca
                      </vs-th>
                      <vs-th sory-key="donationReference" style="width: 200px;">
                        Akcije
                      </vs-th>

                    </template>

                    <template slot-scope="{data}">
                      <vs-tr :key="data[index].id" v-for="(tr, index) in data" :data="tr">
                        <vs-td :data="index">{{ index + 1 }}</vs-td>
                        <vs-td :data="moment(data[index].donationDate).subtract(3, 'hours')">
                          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
                            {{ moment(data[index].donationDate).subtract(3, 'hours').format('DD. MM. YYYY') }}
                          </vs-col>
                        </vs-td>

                        <vs-td :data="data[index].donorName">
                          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
                            {{ data[index].donorName }}
                          </vs-col>
                        </vs-td>

                        <vs-td :data="data[index].donorAddress">
                          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
                            {{ data[index].donorAddress }}
                          </vs-col>
                        </vs-td>

                        <vs-td :data="data[index].donationAmount">
                          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
                            {{ data[index].donationAmount }}
                          </vs-col>
                        </vs-td>

                        <vs-td :data="data[index].donationUnit">
                          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
                            {{ data[index].donationUnit }}
                          </vs-col>
                        </vs-td>

                        <vs-td :data="data[index].donationReference">
                          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
                            {{ data[index].donationReference }}
                          </vs-col>
                        </vs-td>

                        <vs-td :data="data[index].id">
                          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">

                            <!--                        <vs-button type="filled" color="primary" icon="edit" size="small"-->
                            <!--                                   class="vs-lg-12 mt-2" @click.stop="editDonation(data[index].id)">Uredi-->
                            <!--                        </vs-button>-->
                                                    <vs-button type="filled" color="danger" icon="edit" size="small"
                                                               class="vs-lg-12 mt-2 ml-2" @click.stop="removeImportCandidate(index)">Odstrani
                                                    </vs-button>

                          </vs-col>
                        </vs-td>

                      </vs-tr>
                    </template>
                  </vs-table>


                </vs-col>
              </vs-row>
              <vs-row>
                <vs-col vs-type="flex" vs-justify="start" vs-align="start" vs-w="12">
                  <vs-button class="mt-5 mb-3" color="success" icon="upload" @click="importData"
                             v-if="donationsData && donationsData.length > 0">Uvozi podatke
                  </vs-button>
                </vs-col>
              </vs-row>
            </div>
          </div>
        </div>
      </vs-col>
    </vs-row>

    <div v-if="donationsData && donationsData.length > 0" class="mt-10">
      <PrivateDonationsTable :donations-data="donationsData"/>
      <h2 class="mt-10 mr-10 float-right"><span class="text-normal">SKUPAJ:</span> <b class="text-success text-bold">{{
          Number(parseFloat(this.donationsTotal).toFixed(2)).toLocaleString('sl', {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2
          })
        }} eur</b></h2>
    </div>
    <div v-if="donationsData && donationsData.length === 0">
      <vs-alert
        class="mt-5"
        color="danger"
        :title="'Ni donacij'"
        active="true">
        Za izbran mesec in/ali enoto ni donacij zasebnikov.
      </vs-alert>

    </div>


    <div id="parentx">
      <vs-sidebar position-right parent="body" default-index="1" color="primary" class="unitSidebar" spacer
                  v-model="sidebarActive">


      </vs-sidebar>
    </div>

  </div>
</template>

<script>
import Vue from 'vue';
import PrivateDonationsTable from "../../components/Sopotniki/donations/PrivateDonationsTable";
import {slSI} from "vuejs-datepicker/dist/locale";
import sir from "@/assets/images/portrait/sir.svg";
import madam from "@/assets/images/portrait/madam.svg";
import Datepicker from "vuejs-datepicker";
import vSelect from "vue-select";
import convert from "xml-js";

const xlsx = require('json-as-xlsx');

export default {
  name: 'companies',

  components: {
    'v-select': vSelect,
    Datepicker,
    PrivateDonationsTable
  },

  data() {
    return {
      selectedDate: new Date(),
      slSI: slSI,
      importMode: false,
      donationDataToImport: [],

      donationsData: null,
      unitsData: null,
      companyId: null,
      sidebarActive: false,
      unitId: -1,
      donationsTotal: 0,
      fields_to_export: {
        "Datum": {
          field: "donationDate",
          callback: (value) => {
            return this.moment(value).subtract(3, 'hours').format('DD. MM. YYYY');
          },
        },
        "Donator": "donorName",
        "Znesek": "donationAmount",
        "Referenca": "donationReference",
        "Enota": {
          field: "unit.name",
        },
        "Občina/-e": {
          field: "unit.coveredMunicipalities",
        },
      }
    }
  },

  computed: {},

  async mounted() {
    const _this = this;
    this.selectedDate = await this.$store.getters.getSelectedDateForPrivateDonations;

    _this.$vs.loading();

    let p1 = this.getUnits();

    Promise.all([p1]).then(() => {
      _this.$vs.loading.close();
      _this.getDonations();
    });

  },

  beforeRouteLeave(to, from, next) {
    console.log("beforeRouteLeave");
    const _this = this;
    _this.companyId = null;
    _this.sidebarActive = false;
    setTimeout(() => {
      next()
    }, 100);
  },

  methods: {
    onUnitChange() {
      const _this = this;

      _this.getDonations(_this.unitId);
    },

    async getUnits() {
      const _this = this;

      await Vue.prototype.$http.get(Vue.prototype.$config.api.productionNew + 'units')
        .then((res) => {

          _this.unitsData = _.filter(res.data.data, {disabled: 0});
          _this.unitsData.push({
            id: -1,
            name: 'Vse enote',
            coveredMunicipalities: 'podatki za vse enote'
          })
        })
        .catch((error) => {
          console.log("error", error);
          _this.$vs.loading.close();
          return false
        });

    },

    getDonations(unitId) {
      const _this = this;

      console.log("unitId", unitId);
      _this.$vs.loading();

      let month = _this.moment(_this.selectedDate).month();
      let year = _this.moment(_this.selectedDate).year();

      Vue.prototype.$http.get(Vue.prototype.$config.api.productionOld + 'finances/' + year + '/' + month)
        .then((res) => {

          if (unitId) {
            _this.donationsData = _.sortBy(
              _.filter(res.data.data, {unit_id: unitId}),
              ['donationDate']
            );
          } else {
            _this.donationsData = _.sortBy(res.data.data,['donationDate']);
          }


          _this.calculateSum();


          _this.$vs.loading.close();


        })
        .catch((error) => {
          console.log("error", error);
          _this.$vs.loading.close();
          return false
        });
    },

    calculateSum() {
      const _this = this;
      let sum = 0;
      _.each(_this.donationsData, function (val) {
        sum += Number(parseFloat(val.donationAmount.replace(',', '.')));
      });

      _this.donationsTotal = sum;
    },

    onDateChange() {
      this.$store.dispatch('setSelectedDateForPrivateDonations', this.selectedDate);
      this.getDonations();
    },

    customDateFormatter(date) {
      return this.moment(date).format('MMMM, YYYY');
    },

    addDonation() {
      const _this = this;
      _this.$router.push({name: 'PrivateDonationEdit'});
    },

    exportData() {
      const tableData = _.cloneDeep(this.donationsData);

      tableData.push({});

      tableData.push({
        donorName: 'Skupaj:',
        donationAmount: this.donationsTotal
      });


      let data = [
        {
          sheet: 'Donacije zasebnikov',
          columns: [
            {
              label: 'Datum',
              value: (row) => (row.donationDate ? this.moment(row.donationDate).format('DD. MM. YYYY') || '' : ''),
            }, // Run functions
            {
              label: 'Donator',
              value: (row) => (row.donorName ? row.donorName || '' : ''),
            }, // Run functions
            {
              label: 'Znesek',
              value: (row) => (row.donationAmount ? Number(parseFloat(row.donationAmount).toFixed(2)) || '' : '')
            }, // Run functions
            {label: 'Referenca', value: (row) => (row.donationReference ? row.donationReference || '' : '')}, // Run functions
            {label: 'Enota', value: (row) => (row.unit ? row.unit.name : '')}, // Run functions
            {label: 'Občina/-e', value: (row) => (row.unit ? row.unit.coveredMunicipalities || '' : '')}, // Run functions
          ],
          content: tableData,
        },
      ];
      let settings = {
        fileName: this.moment(this.selectedDate).year() + '_' + (this.moment(this.selectedDate).month() + 1) + '_donacije_zasebnikov',
      };
      xlsx(data, settings);

    },

    fileChanged() {
      const _this = this;

      let file = _this.$refs.file;
      console.log(file.files[0]);

      if (file.files[0]) {
        let reader = new FileReader();
        reader.onload = (e) => {
          const text = e.target.result;

          this.parseTextAsXml(text);
        }
        reader.readAsDataURL(file.files[0]);
      }
    },

    parseTextAsXml(text) {
      const _this = this;

      let parser = new DOMParser(),
        xmlDom = parser.parseFromString(text.trim(), "text/xml");

      let options = {
        compact: true
      };

      console.log("text", atob(text.replace('data:text/xml;base64,', '')));


      let str = convert.xml2js(atob(text.replace('data:text/xml;base64,', '')), options);


      let nlbObj = str;
      console.log("----> ", nlbObj.Document.BkToCstmrAcctRpt.Rpt.Ntry);

      this.donationDataToImport = [];

      _.each(nlbObj.Document.BkToCstmrAcctRpt.Rpt.Ntry, function (key, index) {

        if (key.CdtDbtInd._text === 'CRDT') {


          console.log("key", key);

          let donationItem = {
            'donationId': key.NtryDtls.TxDtls.Refs.TxId._text,
            'donationDate': _this.moment(key.NtryDtls.TxDtls.RltdDts.IntrBkSttlmDt._text, 'YYYY-MM-DD').valueOf(),
            // 'donationDateFormatted': moment(key.NtryDtls.TxDtls.RltdDts.IntrBkSttlmDt, 'YYYY-MM-DD').format('DD.MM.YYYY'),
            'donorName': key.NtryDtls.TxDtls.RltdPties.Dbtr.Nm._text,
            'donorAddress': key.NtryDtls.TxDtls.RltdPties.Dbtr.PstlAdr.AdrLine[0]._text,
            'donationAmount': key.Amt._text,
            'donationReference': typeof key.NtryDtls.TxDtls.RmtInf.Strd != 'undefined' ? key.NtryDtls.TxDtls.RmtInf.Strd.CdtrRefInf.Ref._text : "",
            'donationUnit': typeof key.NtryDtls.TxDtls.RmtInf.Strd != 'undefined' ? key.NtryDtls.TxDtls.RmtInf.Strd.CdtrRefInf.Ref._text.substring(4, 7) : "",
            'unit_id': 1
          };

          _this.donationDataToImport.push(donationItem);
        }

      });


    },

    removeImportCandidate(itemIndex) {
      this.donationDataToImport.splice(itemIndex, 1);
    },

    async importData() {
      const _this = this;
      const promises = [];

      for (const importedDonation of _this.donationDataToImport) {

        const result = await _this.saveDonation(importedDonation);
        promises.push(result);
      }
      const results = await Promise.all(promises);
      console.log("results:", results);
      _this.getDonations();
    },

    async saveDonation(donationItem) {
      const _this = this;

      _this.$vs.loading();

      let donationData = {
        donationAmount: Number(donationItem.donationAmount.replace(',','.')),
        donationDate: _this.moment(donationItem.donationDate).valueOf(),
        donationId: donationItem.donationId ? donationItem.donationId : -1,
        donationReference: _this.$globalFunctions.isAdmin() ? donationItem.donationReference : 'G',
        donationUnit: "",
        donorAddress: donationItem.donorAddress,
        donorName: donationItem.donorName,
        id: donationItem.id ? donationItem.id : null,
        unit_id: _this.unitId,
      };

      await Vue.prototype.$http.post(Vue.prototype.$config.api.productionOld + 'finances', donationData)
        .then((res) => {
          _this.$vs.loading.close();
          _this.$vs.notify({
            color: 'success',
            title: 'Donacija ' + donationItem.donorName + ' uspešno shranjena!',
            text: '',
            position: 'top-right'
          });
        })
        .catch((error) => {
          console.log("error", error);
          _this.$vs.loading.close();
          return false
        });
    },


  }
}
</script>

