<template>

  <div>

    <vs-table :data="donationsData"
              :pagination="donationsData.length > maxItems ? true : false" :max-items="maxItems">
      <template slot="header">

        <div class="d-flex mi-align-horizontal-left">
          <div class="d-inline-block float-left">
            <h2>Donacije zasebnikov
            </h2>
          </div>
          <vs-chip class="mt-1 ml-3">{{ donationsData.length }}</vs-chip>
        </div>

      </template>
      <template slot="thead">
        <vs-th sort-key="id">
          <!-- empty -->
        </vs-th>
        <vs-th sort-key="donationDate">
          Datum
        </vs-th>
        <vs-th sort-key="donorName">
          Donator
        </vs-th>
        <vs-th sort-key="donationAmount">
          Znesek
        </vs-th>
        <vs-th sort-key="unit_id">
          Enota
        </vs-th>
        <vs-th sory-key="donationReference">
          Referenca
        </vs-th>
        <vs-th sory-key="donationReference" style="width: 200px;">
          Akcije
        </vs-th>

      </template>

      <template slot-scope="{data}">
        <vs-tr :key="data[index].id" v-for="(tr, index) in data" :data="tr">
          <vs-td :data="index" style="width: 50px;">{{ index + 1 }}</vs-td>
          <vs-td :data="moment(data[index].donationDate).subtract(3, 'hours')" style="width: 200px;">
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
              {{ moment(data[index].donationDate).subtract(3, 'hours').format('DD. MM. YYYY') }}
            </vs-col>
          </vs-td>

          <vs-td :data="data[index].donorName">
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
              {{ data[index].donorName }}
            </vs-col>
          </vs-td>

          <vs-td :data="data[index].donationAmount">
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
              {{data[index].donationAmount}}
            </vs-col>
          </vs-td>

          <vs-td :data="data[index].unit.name">
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
              {{ data[index].unit.name }}
            </vs-col>
          </vs-td>

          <vs-td :data="data[index].donationReference">
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
              {{ data[index].donationReference }}
            </vs-col>
          </vs-td>

          <vs-td :data="data[index].id">
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">

              <vs-button type="filled" color="primary" icon="edit" size="small"
                         class="vs-lg-12 mt-2" @click.stop="editDonation(data[index].id)">Uredi
              </vs-button>
              <vs-button type="filled" color="danger" icon="edit" size="small"
                         class="vs-lg-12 mt-2 ml-2" @click.stop="beforeDeleteDonation(data[index])">Izbiriši
              </vs-button>

            </vs-col>
          </vs-td>

        </vs-tr>
      </template>
    </vs-table>


  </div>
</template>

<script>
import Vue from 'vue';


export default {
  name: 'companies-table',
  props: {
    donationsData: Array,
  },
  components: {},

  data() {
    return {
      maxItems: 500,

    }
  },

  computed: {},

  mounted() {
    const _this = this;

  },

  destroyed() {
    console.log("destroyed");
    const _this = this;

  },

  beforeDestroy() {
    console.log("beforeDestroy");
    const _this = this;


  },

  methods: {
    editDonation(donationId) {
      const _this = this;
      _this.$router.push({name: 'PrivateDonationEdit', params: {donationId: donationId}});
    },

    getFiltered(e) {
      console.log(e) //output the filtered items
    },

    beforeDeleteDonation(donation) {
      const _this = this;

      Vue.$confirm({
        title: 'Ste prepričani, da želite izbrisati donacijo zasebnika ' + donation.donorName + ' ?',
        message: 'Po potrditvi bo donacija trajno izbrisana.',
        button: {
          yes: 'Izbriši',
          no: 'Prekliči'
        },
        callback: confirm => {
          console.log("confirm", confirm);
          if(confirm) {
            _this.deleteDonation(donation);
          }
        }
      });
    },

    deleteDonation(donation) {
      const _this = this;

      _this.$vs.loading();

      let donationData = donation;
      donationData.deleted = true;

      Vue.prototype.$http.post(Vue.prototype.$config.api.productionOld + 'finances', donationData)
          .then((res) => {
            _this.$vs.loading.close();
            _this.$parent.getDonations();
          })
          .catch((error) => {
            console.log("error", error);
            _this.$vs.loading.close();
            return false
          });
    },

    stopTheEvent: (event) => {
      event.stopPropagation();
      event.preventDefault();
    },

  }
}
</script>

